import Vue from 'vue';
import Router from 'vue-router';

import { auth } from './firebase';

Vue.use(Router);

const authGuard = async (to, from, next) => {
  if (!auth.currentUser) {
    return next({ name: 'login' });
  }

  const token = await auth.currentUser.getIdTokenResult();

  if (token.claims.role === 'admin') {
    return next();
  }

  if (token.claims.role === 'statistics' && to.name === 'statistics') {
    return next();
  } else if (token.claims.role === 'statistics' && to.name !== 'statistics') {
    return next({ name: 'statistics' });
  }

  next({ name: 'login' });
};

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () =>
        import(/* webpackChunkName: "login" */ './views/Login.vue')
    },
    {
      path: '/',
      name: 'dashboard',
      redirect: '/studios',
      beforeEnter: authGuard,
      component: () =>
        import(/* webpackChunkName: "dashboard" */ './views/Dashboard.vue'),
      children: [
        {
          path: '/studios',
          name: 'studios',
          component: () =>
            import(/* webpackChunkName: "studios" */ './views/Studios.vue')
        },
        {
          path: '/studios/create',
          name: 'studio-create',
          component: () =>
            import(/* webpackChunkName: "studio-create" */ './views/Studio.vue')
        },
        {
          path: '/studios/:id',
          name: 'studio-details',
          component: () =>
            import(
              /* webpackChunkName: "studio-details" */ './views/Studio.vue'
            )
        },
        {
          path: '/studios/:id/course-rooms',
          name: 'studio-course-rooms',
          component: () =>
            import(
              /* webpackChunkName: "studio-course-rooms" */ './views/CourseRooms.vue'
            )
        },
        {
          path: '/studios/:id/course-rooms/create',
          name: 'course-room-create',
          component: () =>
            import(
              /* webpackChunkName: "course-room-create" */ './views/CourseRoom.vue'
            )
        },
        {
          path: '/studios/:id/course-rooms/:courseRoomId',
          name: 'course-room-details',
          component: () =>
            import(
              /* webpackChunkName: "course-room-details" */ './views/CourseRoom.vue'
            )
        },
        {
          path: '/holdings',
          name: 'holdings',
          component: () =>
            import(/* webpackChunkName: "holdings" */ './views/Holdings.vue')
        },
        {
          path: '/holdings/create',
          name: 'holding-create',
          component: () =>
            import(
              /* webpackChunkName: "holding-create" */ './views/Holding.vue'
            )
        },
        {
          path: '/holdings/:id',
          name: 'holding-details',
          component: () =>
            import(
              /* webpackChunkName: "holding-details" */ './views/Holding.vue'
            )
        },
        {
          path: '/studios/:studioId/courses',
          name: 'courses',
          component: () =>
            import(/* webpackChunkName: "courses" */ './views/Courses.vue')
        },
        {
          path: '/studios/:studioId/courses/create',
          name: 'course-create',
          component: () =>
            import(/* webpackChunkName: "course-create" */ './views/Course.vue')
        },
        {
          path: '/studios/:studioId/courses/:id',
          name: 'course-details',
          component: () =>
            import(
              /* webpackChunkName: "course-details" */ './views/Course.vue'
            )
        },
        {
          path: '/promotions',
          name: 'promotions',
          component: () =>
            import(
              /* webpackChunkName: "promotions" */ './views/Promotions.vue'
            )
        },
        {
          path: '/promotions/create',
          name: 'promotion-create',
          component: () =>
            import(
              /* webpackChunkName: "promotion-create" */ './views/Promotion.vue'
            )
        },
        {
          path: '/promotions/:id',
          name: 'promotion-details',
          component: () =>
            import(
              /* webpackChunkName: "promotion-details" */ './views/Promotion.vue'
            )
        },
        {
          path: '/broadcasts',
          name: 'broadcasts',
          component: () =>
            import(
              /* webpackChunkName: "broadcasts" */ './views/Broadcasts.vue'
            )
        },
        {
          path: '/broadcasts/create',
          name: 'broadcast-create',
          component: () =>
            import(
              /* webpackChunkName: "broadcast-create" */ './views/Broadcast.vue'
            )
        },
        {
          path: '/broadcasts/:id',
          name: 'broadcast-details',
          component: () =>
            import(
              /* webpackChunkName: "broadcast-details" */ './views/Broadcast.vue'
            )
        },
        {
          path: '/statistics',
          name: 'statistics',
          component: () =>
            import(
              /* webpackChunkName: "statistics" */ './views/Statistics.vue'
            )
        },
        {
          path: '/trainers',
          name: 'trainers',
          component: () =>
            import(/* webpackChunkName: "trainers" */ './views/Trainers.vue')
        },
        {
          path: '/trainers/create',
          name: 'trainer-create',
          component: () =>
            import(
              /* webpackChunkName: "trainer-create" */ './views/Trainer.vue'
            )
        },
        {
          path: '/trainers/:id',
          name: 'trainer-details',
          component: () =>
            import(
              /* webpackChunkName: "trainer-details" */ './views/Trainer.vue'
            )
        },
        {
          path: '/trainers/:trainerId/time-records',
          name: 'time-records',
          component: () =>
            import(
              /* webpackChunkName: "course-time-records" */ './views/CourseTimeRecords.vue'
            )
        },
        {
          path: '/trainers/:trainerId/course-time-records/create',
          name: 'course-time-record-create',
          component: () =>
            import(
              /* webpackChunkName: "course-time-record-create" */ './views/CourseTimeRecord.vue'
            )
        },
        {
          path: '/trainers/:trainerId/course-time-records/:id',
          name: 'course-time-record-details',
          component: () =>
            import(
              /* webpackChunkName: "course-time-record-details" */ './views/CourseTimeRecord.vue'
            )
        },
        {
          path: '/trainers/:trainerId/time-records/create',
          name: 'time-record-create',
          component: () =>
            import(
              /* webpackChunkName: "time-record-create" */ './views/TimeRecord.vue'
            )
        },
        {
          path: '/trainers/:trainerId/time-records/:id',
          name: 'time-record-details',
          component: () =>
            import(
              /* webpackChunkName: "time-record-details" */ './views/TimeRecord.vue'
            )
        },
        {
          path: '/bookkeeping',
          name: 'bookkeeping',
          component: () =>
            import(/* webpackChunkName: "bookkeeping" */ './views/Invoices.vue')
        },
        {
          path: '/devices',
          name: 'devices',
          component: () =>
            import(/* webpackChunkName: "devices" */ './views/Devices.vue')
        }
      ]
    }
  ]
});

export default router;
