import format from 'date-fns/format';

export const weekday = weekday => {
  switch (weekday) {
    case 0:
      return 'Sonntag';
    case 1:
      return 'Montag';
    case 2:
      return 'Dienstag';
    case 3:
      return 'Mittwoch';
    case 4:
      return 'Donnerstag';
    case 5:
      return 'Freitag';
    case 6:
      return 'Samstag';
  }
};

export const month = month => {
  switch (month) {
    case 0:
      return 'Januar';
    case 1:
      return 'Februar';
    case 2:
      return 'März';
    case 3:
      return 'April';
    case 4:
      return 'Mai';
    case 5:
      return 'Juni';
    case 6:
      return 'Juli';
    case 7:
      return 'August';
    case 8:
      return 'September';
    case 9:
      return 'Oktober';
    case 10:
      return 'November';
    case 11:
      return 'Dezember';
  }
};

export const date = (value, f) => {
  try {
    return format(new Date(value), f);
  } catch (err) {
    return 'Invalid Date';
  }
};

export const courseRoom = (value, rooms) => {
  const room = rooms.filter(room => room.id === value)[0];

  if (room) {
    return room.name;
  }

  return '';
};
