import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import App from './App.vue';
import router from './router';
import { weekday, month, date, courseRoom } from './filters';
// import store from './store'
import { auth } from './firebase';

Vue.config.productionTip = false;

if (process.env.ENVIRONMENT === 'production') {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_IO_PROJECT,
    environment: process.env.VUE_APP_SENTRY_IO_ENV,
    integrations: [new Integrations.Vue({ Vue, attachProps: true })]
  });
}

Vue.filter('weekday', weekday);
Vue.filter('month', month);
Vue.filter('date', date);
Vue.filter('courseRoom', courseRoom);

let app;

auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      // store,
      render: h => h(App)
    }).$mount('#app');
  }
});
